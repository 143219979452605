import { AiOutlineInstagram } from '@react-icons/all-files/ai/AiOutlineInstagram';
import { FaFacebook } from '@react-icons/all-files/fa/FaFacebook';
import { FaMediumM } from '@react-icons/all-files/fa/FaMediumM';
import { ImPinterest } from '@react-icons/all-files/im/ImPinterest';
import { Link } from 'gatsby';
import React, { useState } from 'react'
import "./Footer.scss";
import { WhiteLogo } from '../../svg';
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import {
    // regex,
    caseSuccess,
    caseError,
    caseErrorCheck,
    caseSuccessCheck,
} from "../Validations/SubscriptionForm";

const Footer = () => {
    const [email, setEmail] = useState("");
    const [check, setCheck] = useState(false);
    const [response, setResponse] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [checkResponse, setCheckResponse] = useState("");
    const [accessToken, setAccessToken] = useState('SW8DnHlcXfL0qZ3p3A6JVFCGuZHgbQBY')
    const [refreshToken, setRefreshToken] = useState('6A9C0ye3apjd8TqtIdXNiKaPCqtEuLu9')

    const handleChange = (e) => {
        setEmail(e.target.value);
    };
    const checkInput = (e) => {
        setCheck(e.target.checked);
    };

    function resLoad(setRes, setLoad) {
        setResponse(setRes);
        setIsLoading(setLoad);
    }

    const refreshTokenUrl = `https://auth.aweber.com/oauth2/token?grant_type=refresh_token&refresh_token=${refreshToken}`;

    const refreshAccessToken = async () => {
        try {
            const res = await fetch(refreshTokenUrl, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    'Authorization': 'Basic ' + btoa('FawoOSmefXDLBCV6xcxybCfKb2Fiu2wC:TXyuJq1DjoM1sMkkgpEtv84h71twxLXf'),
                },
                method: "POST",
            })
            const data = await res.json()
            setAccessToken(data.access_token);

            //after refresh token adding subscriber
            // ----------------------------------------------------------------------------------------------------

            await fetch("https://api.aweber.com/1.0/accounts/1958895/lists/6347465/subscribers", {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${data.access_token}`
                },
                method: "POST",
                body: JSON.stringify({
                    email: email
                })
            })
                .then(data => {
                    if (data.status == 201) {
                        caseSuccess("responseMsgMailFooter");
                        resLoad("You have successfully subscribed.", false);
                        setEmail('');
                        setCheck(false)
                    }
                    else {
                        //in case the email already used or unacceptable email
                        caseError("responseMsgMailFooter");
                        resLoad("Use Another Email.", false);
                    }
                })
                .catch(err => {
                    caseError("responseMsgMailFooter");
                    resLoad("Invalid Email.", false);
                })
            // ----------------------------------------------------------------------------------------------------
        } catch (e) {
            console.log(e)
        }
    }
    const Useremail = async () => {
        setIsLoading(true);
        if (email != "") {
            await fetch("https://api.aweber.com/1.0/accounts/1958895/lists/6347465/subscribers", {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${accessToken}`
                },
                method: "POST",
                body: JSON.stringify({
                    email: email
                })
            })
                .then(data => {
                    if (data.status == 201) {
                        caseSuccess("responseMsgMailFooter");
                        resLoad("You have successfully subscribed.", false);
                        setEmail('');
                        setCheck(false)
                    }
                    else if (data.status == 401) {
                        refreshAccessToken();
                    }
                    else {
                        //in case the email already used or unacceptable email
                        caseError("responseMsgMailFooter");
                        resLoad("Use Another Email.", false);
                    }
                })
                .catch(err => {
                    caseError("responseMsgMailFooter");
                    resLoad("Invalid Email.", false);
                })
        } else {
            caseError("responseMsgMailFooter");
            resLoad("Please enter all valid details.", false);
        }
    };
    return (
        <footer className='footer'>
            <div className="container py-5">
                <div className="top-div">
                    <Link to='/' className='div-span'><WhiteLogo /></Link>
                    <span className='div-span'>
                        <div className="social_links_facebook">
                            <a
                                href="https://facebook.com/Phonera.in"
                                className="pk-social-links-link"
                                target="_blank"
                                rel="nofollow noopener"
                                aria-label="Facebook"
                            >
                                <div className="icon_facebook position-relative">
                                    <FaFacebook className="fs-3 ms-3 facebook1 position-absolute" />
                                </div>
                            </a>
                        </div>
                        <div className="social_links_insta" data-id="instagram">
                            <a
                                href="https://www.instagram.com/phonera.in"
                                className="pk-social-links-link"
                                target="_blank"
                                rel="nofollow noopener"
                                aria-label="Instagram"
                            >
                                <div className="icon_insta position-relative">
                                    <AiOutlineInstagram className="fs-3 ms-3 insta1 position-absolute" />
                                </div>
                            </a>
                        </div>
                        <div className="social_links_pinterest">
                            <a
                                href="https://pinterest.com/Phonerain/_saved"
                                className="pk-social-links-link"
                                target="_blank"
                                rel="nofollow noopener"
                                aria-label="Pinterest"
                            >
                                <div className="icon_pinterest position-relative">
                                    <ImPinterest className="fs-3 ms-3 pinterest1 position-absolute" />
                                </div>
                            </a>
                        </div>
                        <div className="social_links_medium">
                            <a
                                href="https://medium.com/@phonera"
                                className="pk-social-links-link"
                                target="_blank"
                                rel="nofollow noopener"
                                aria-label="Medium"
                            >
                                <div className="icon_medium position-relative">
                                    <FaMediumM className="fs-3 ms-3 medium1 position-absolute" />
                                </div>
                            </a>
                        </div>
                    </span>
                </div>
                <div className="bottom-div row">
                    <div className="section-1 col-md-6 d-flex">
                        <ul className="div-ul col-md-6">
                            <li>
                                <Link to="/about/">About Us</Link>
                            </li>
                            <li>
                                <Link to="/contact/">Contact Us</Link>
                            </li>
                            <li>
                                <Link to="/category/news/">News</Link>
                            </li>
                            <li>
                                <Link to="/category/comparison/">Comparison</Link>
                            </li>
                            <li>
                                <Link to="/category/phones/">Phones</Link>
                            </li>
                            <li>
                                <Link to="/category/reviews/">Reviews</Link>
                            </li>
                        </ul>
                        <ul className="div-ul2 col-md-6">
                            <li>
                                <Link to="/category/spec/">Specifications</Link>
                            </li>
                            <li>
                                <Link to="/terms-of-use/">Terms of Use</Link>
                            </li>
                            <li>
                                <Link to="/privacy-policy/">Privacy Policy</Link>
                            </li>
                            <li className="li-text">
                                <div>© 2022 Phonera is a tech media company. All rights reserved. Our website keeps you update related to all tech crazy stuffs daily. <br /> Made with love by <a href='https://iwebcode.design/' >IWEBCODE</a>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div className="section-2 col-md-6">
                        <div className='content'>
                            <h2 className='heading'>Get latest tech News updates</h2>
                            <p style={{ margin: '0px 0 15px 0px', fontSize: '14px', lineHeight: '20px' }}>It’s not just that we use technology but live through it. Join us on the unravelling journey for an inside look at the fanatical world of gadgets and gizmos, so that you can be a mere spectator but able to view the changes brought about by them.</p>
                            <div className="div-form">
                                <form >
                                    <div className='input-email'>
                                        <input placeholder="Enter your email" aria-label="Enter your email" type="email" value={email}
                                            onChange={handleChange} />
                                    </div>
                                    <button onClick={(e) => {
                                        e.preventDefault();
                                        if (!check) {
                                            caseErrorCheck("checkResMailFooter");
                                            setCheckResponse(
                                                "Please confirm that you agree with our policies."
                                            );
                                        } else {
                                            Useremail();
                                        }
                                    }}>
                                        SUBSCRIBE
                                    </button>
                                    {isLoading ? <LoadingSpinner /> : null}
                                    <p className="responseMsgMailFooter">{response}</p>
                                    <p className="checkResMailFooter">{checkResponse}</p>
                                    <div className='position-relative privacy-check'>
                                        <input className="" type="checkbox" checked={check}
                                            onChange={(e) => {
                                                checkInput(e);
                                                if (check) {
                                                    caseErrorCheck("checkResMailFooter");
                                                    setCheckResponse(
                                                        "Please confirm that you agree with our policies."
                                                    );
                                                } else {
                                                    caseSuccessCheck("checkResMailFooter");
                                                    setCheckResponse("");
                                                }
                                            }} />
                                        <p className="">By checking this box, you confirm that you have read and are agreeing to our terms of use regarding the storage of the data submitted through this form.</p>
                                    </div>
                                </form>
                                <p className="note">Your <Link to="/privacy-policy/">privacy</Link> is important to us</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}
export default Footer